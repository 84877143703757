import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import RichText from '~components/RichText';
import Link from '~components/Link';
import Container from '~components/Container';
import { useLocation } from '@reach/router';

const useStyles = makeStyles({
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  publicationLinks: {
    fontSize: '18px',
    lineHeight: '21px',
    color: '#458FCE'
  },
  ulStyle: {
    marginTop: '22px',
    paddingInlineStart: '18px'
  },
  liStyle: {
    color: '#000000',
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: '15px'
  }
});

const FounderSection = ({
  unique_hash_id,
  founder_section_title,
  founder_name,
  founder_position,
  founder_text,
  founder_image,
  founder_content_title,
  founder_publications
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const targetElement = useRef();

  const location = useLocation();

  useEffect(() => {
    if (location?.hash && location.hash.substring(1) === unique_hash_id) {
      targetElement?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      });
    }
  }, [location?.hash, unique_hash_id]);

  return (
    <div ref={targetElement}>
      <Container style={{ marginTop: '100px', marginBottom: '100px' }}>
        <RichText
          html={founder_section_title && founder_section_title.html}
          mode="teamPageSectionTitle"
          verticalSpacing={0}
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={isXs ? 2 : 8}
        >
          <Grid item xs={12} sm={3}>
            <img
              src={founder_image && founder_image.url}
              alt={founder_image && founder_image.alt}
              className={classes.media}
            />
            <RichText
              html={founder_name && founder_name.html}
              mode="teamPageMemberName"
              verticalSpacing={0}
            />
            <RichText
              html={founder_position && founder_position.html}
              mode="teamPageMemberPosition"
              verticalSpacing={0}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <RichText
              html={founder_text && founder_text.html}
              mode="teamPageFounderText"
              verticalSpacing={0}
            />
            <RichText
              html={founder_content_title && founder_content_title.html}
              mode="teamPageFounderPublicationsTitle"
              verticalSpacing={0}
            />
            <ul className={classes.ulStyle}>
              {founder_publications &&
                founder_publications.map(publication => (
                  <li className={classes.liStyle} key={publication && publication.title}>
                    <Link
                      to={publication && publication.link && publication.link.url}
                      target="_blank"
                      className={classes.publicationLinks}
                    >
                      {publication && publication.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
FounderSection.propTypes = {
  unique_hash_id: PropTypes.string,
  founder_section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  founder_name: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  founder_position: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  founder_text: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  founder_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  founder_content_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  founder_publications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

FounderSection.defaultProps = {
  unique_hash_id: ''
};

export default FounderSection;
